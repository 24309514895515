import type { AuthToken } from '~/types/Auth'
import type { AuthFetch } from '~/types/AuthFetch'

/**
 * Provider to get another valid token from auth for the currently logged user.
 *
 * @param authFetch - The transporter.
 * @returns The api call function.
 */
export function provideGetNewToken(authFetch: AuthFetch) {
  return function () {
    return authFetch.$get<AuthToken>('/api/user/token/')
  }
}
